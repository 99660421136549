<template>
  <div>
    <div class="tab-bar">
      <div
        v-for="(tab, key) in tabbar"
        :key="key"
        @click="changeTabBar(key, 'checkedTab')"
        :class="{ active: key == checkedTab }"
      >
        {{ tab.name || tab }}
      </div>
    </div>

    <div
      class="tab-bar"
      v-if="
        tabbar[checkedTab].children && tabbar[checkedTab].children.length > 0
      "
    >
      <div
        v-for="childTab in tabbar[checkedTab].children"
        :key="childTab.id"
        @click="changeTabBar(childTab.id, 'checkedChildTab')"
        :class="{ active: checkedChildTab === childTab.id }"
      >
        {{ childTab.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CSTabBar",
  props: {
    tabbar: Object,
    checkedTab: [String, Number],
    checkedChildTab: [String, Number],
  },
  data() {
    return {};
  },
  methods: {
    changeTabBar(id, property) {
      let key = id;
      if (!isNaN(id)) {
        key = Number(id);
      }
      this.$vc.emit("clearLoading", {});
      this.$emit("changeTabBar", key, property);
    },
  },
};
</script>
